<template>
    <Lazy>
    <div class="row justify-content-center">
        <div class="col-md-5">
            <h1 class="text-center">Update</h1>
                <form @submit.prevent="onUpdateForm" style="padding: 30px">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" v-model="service.name" required>
                               <label style="color: red"><b>COVID: </b></label>&nbsp;&nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="service.covid"><br><br>
                            </div>
                            <label><b>No Service: </b></label>&nbsp;&nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="noservice"><br><br>
                            <div v-if="noservice !== true" class="form-group">
                                <label>Service Date/Time</label>
                                <input type="datetime-local" class="form-control" v-model="service.date">
                            </div>
                            <br>
                            <div class="form-group">
                                <label>Date of Death</label>
                                <input type="datetime-local" class="form-control" v-model="service.dod">
                            </div>
                            <br>
                            <div class="form-group">
                                <label>Location of Death</label>
                                <input type="text" class="form-control" v-model="service.deathloc">
                            </div>
                            <br>
                            <label>Body Location:</label>
                            <br>
                            <select class=form-control v-model="service.bl">
                                <option v-for="(bl, id) in BLs" :key="id" :value="bl.name">{{bl.name}}</option>
                            </select>
                            <br>
                            <div class="form-group">
                                <label>Family Coming In: </label><input type="datetime-local" class="form-control" v-model="service.familyin">
                            </div>
                            <br>
                            <input type="button" value="Add Casket" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#myModal">
                                
                            <div id="myModal" class="modal fade" role="dialog">
                              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            
                                <!-- Modal content-->
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h2>Add Casket</h2>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body" style="padding: 1px">
                                      <div class="form-group">
                                        <label>Color</label>
                                        <select class=form-control v-model="colorfilter">
                                            <option value="All Colors" selected>All Colors</option>
                                            <option v-for="(color, id) in Colors" :key="id" :value="color.name">{{color.name}}</option>
                                        </select>
                                    </div>
                                    <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>None</td>
                                                    <td></td>
                                                    <td>
                                                        <button v-if="service.casket == null && service.casket !== undefined" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectCasket(null)">Select</button>
                                                        <button v-if="service.casket !== null" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectCasket(null)">Select</button>
                                                    </td>
                                                </tr>
                                                <tr v-for="casket in filteredSearch" :key="casket.key">
                                                    <td>{{ casket.name }}</td>
                                                    <td>{{ casket.notes }}</td>
                                                    <td>
                                                        <button v-if="service.casket == casket.name" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectCasket(casket.name)">Select</button>
                                                        <button v-if="service.casket !== casket.name" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectCasket(casket.name)">Select</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                            
                              </div>
                            </div>    
                            
                            <div class="theService">
                                <br>
                            <h3><b>Service</b></h3>
                            <input type="button" value="Choose Service Location" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#slmodal">
                                
                            <div id="slmodal" class="modal fade" role="dialog">
                              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            
                                <!-- Modal content-->
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h2>Service Location</h2>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body" style="padding: 1px">
                                    <div class="form-group">
                                        <input type='text' class="form-control" placeholder="Search" v-model="searchsl"/>
                                    </div>
                                    <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>None</td>
                                                    <td></td>
                                                    <td>
                                                        <button v-if="service.serviceLoc == null && service.serviceLoc !== undefined" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectSL(null)">Select</button>
                                                        <button v-if="service.serviceLoc !== null" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectSL(null)">Select</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Other</td>
                                                    <td><input type="text" class="form-control" v-model="otherServiceLoc"></td>
                                                    <td>
                                                        <button v-if="service.serviceLoc == 'Other'" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectSL('Other')">Select</button>
                                                        <button v-if="service.serviceLoc !== 'Other'" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectSL('Other')">Select</button>
                                                    </td>
                                                </tr>
                                                <tr v-for="sl in filteredSearchSL" :key="sl.key">
                                                    <td>{{ sl.name }}</td>
                                                    <td>{{ sl.address }}</td>
                                                    <td>
                                                        <button v-if="service.serviceLoc == sl.name" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectSL(sl.name)">Select</button>
                                                        <button v-if="service.serviceLoc !== sl.name" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectSL(sl.name)">Select</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                            
                              </div>
                            </div>
                            <br>
                            <br>
                            <div class="form-group">
                                <label><b>Visitation: </b>&nbsp;&nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="showVis" v-on:click="UncheckVis($event)"></label><br>
                                <div v-if="showVis == true"><label>From: </label><input type="datetime-local" class="form-control" v-model="service.visitationstart">
                                <label>To: </label><input type="time" class="form-control" v-model="service.visitationend"></div>
                            </div>
                            <br>
                            <label><b>Service Requirements:</b></label>
                            <table class="table">
                                <thead><td></td><td></td></thead>
                                 <tr v-for="(sr, id) in Srs" :key="id">
                                     <td v-if="sr.name != 'None'"><input class="form-check-input" type="checkbox" :id="sr.name" v-on:click="addSR(sr, $event)" :checked="this.checkedSR.find(x => x.name === sr.name)"></td>
                                     <td><input v-if="this.checkedSR.find(x => x.name === sr.name) && sr.name != 'None'" class="form-check-input" type="checkbox" :name="sr.done" :id="sr.done" v-on:click="checkSR(sr, $event)" :checked="this.checkedSR.find(x => x.name === sr.name).done"></td>
                                     <td v-if="sr.name != 'None'"><label :for="sr.name">{{ sr.name }}</label></td>
                                </tr>
                            </table>
                            <div class="form-group">
                                <label>Music Notes</label>
                                <textarea name="text" rows="3" cols="10" wrap="soft" class="form-control" v-model="service.music"> </textarea>
                             </div>
                             <div class="form-group">
                                <label>Other Service Notes</label>
                                <textarea name="text" rows="3" cols="10" wrap="soft" class="form-control" v-model="service.notes"> </textarea>
                            </div>
                            <div v-for="(field, id) in CustomFields" :key="id">
                            <label v-if="field.category == 'Service'">{{field.name}}</label>&nbsp;&nbsp;
                            <input v-if="field.category == 'Service' && field.type == 'text'" type="text" class="form-control" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <input v-if="field.category == 'Service' && field.type == 'checkbox'" class="form-check-input" type="checkbox" :id="1234" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <br>
                            </div>
                             </div>
                             <br>
                             <div class="theCem">
                            <h3><b>Cemetery</b></h3>
                            <input type="button" value="Choose Cemetery" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#cemmodal">
                                
                            <div id="cemmodal" class="modal fade" role="dialog">
                              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            
                                <!-- Modal content-->
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h2>Cemetery</h2>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body" style="padding: 1px">
                                    <div class="form-group">
                                        <input type='text' class="form-control" placeholder="Search" v-model="searchcem"/>
                                    </div>
                                    <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>None</td>
                                                    <td></td>
                                                    <td>
                                                        <button v-if="service.cem == null && service.cem !== undefined" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectCL(null)">Select</button>
                                                        <button v-if="service.cem !== null" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectCL(null)">Select</button>
                                                    </td>
                                                </tr>
                                                 <tr>
                                                    <td>Other</td>
                                                    <td><input type="text" class="form-control" v-model="otherCem"></td>
                                                    <td>
                                                        <button v-if="service.cem == 'Other'" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectCL('Other')">Select</button>
                                                        <button v-if="service.cem !== 'Other'" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectCL('Other')">Select</button>
                                                    </td>
                                                </tr>
                                                <tr v-for="cl in filteredSearchCem" :key="cl.key">
                                                    <td>{{ cl.name }}</td>
                                                    <td>{{ cl.address }}</td>
                                                    <td>
                                                        <button v-if="service.cem == cl.name" type="button" class="btn btn-success" data-bs-dismiss="modal" @click="selectCL(cl.name)">Select</button>
                                                        <button v-if="service.cem !== cl.name" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="selectCL(cl.name)">Select</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                            
                              </div>
                            </div>
                            <label>&nbsp;Contacted: &nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="service.cemContacted"></label>
                            <br>
                            <br>
                            <label>Container &nbsp;&nbsp; Done: &nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="service.containerDone"></label>
                            <select class=form-control v-model="service.container">
                                <option v-for="(container, id) in Containers" :key="id" :value="container.name">{{container.name}}</option>
                            </select>
                            <div class="form-group">
                                <label>Container Company</label>
                                <input type="text" class="form-control" v-model="service.containercompany">
                            </div>
                            <br>
                            <label><b>Cremation: </b></label>&nbsp;&nbsp;<input class="form-check-input" type="checkbox" :id="1234" v-model="service.cremation">
                            </div>
                             <div v-for="(field, id) in CustomFields" :key="id">
                            <label v-if="field.category == 'Cemetery'">{{field.name}}</label>&nbsp;&nbsp;
                            <input v-if="field.type == 'text' && field.category == 'Cemetery'" type="text" class="form-control" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <input v-if="field.type == 'checkbox' && field.category == 'Cemetery'" class="form-check-input" type="checkbox" :id="1234" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <br>
                            </div>
                            <br>
                            <div>
                            <h3><b>Vehicles/Personnel</b></h3>
                            <label>Director:</label>
                            <br>
                            <select class=form-control v-model="service.dir">
                                <option v-for="(pers, id) in Perss" :key="id" :value="pers.name">{{pers.name}}</option>
                            </select>
                             </div>
                            <br>
                            <table style="width: 100%">
                                <tr>
                                    <td valign=top>
                                        <label><b>Select Vehicles:</b></label>
                                        <table class="table" style="width: 50%">
                                            <thead><td></td><td></td></thead>
                                            <tr v-for="(veh, id) in Vehs" :key="id">
                                              <td v-if="veh.name != 'Limo'"><input class="form-check-input" type="checkbox" :id="veh.name" v-on:click="addVeh(veh.name, $event)" :checked="this.service.veh.includes(veh.name)"></td>
                                              <td v-if="veh.name != 'Limo'"><label :for="veh.name">{{ veh.name }}</label></td>
                                              <td v-if="veh.name == 'Limo'"><input class="form-check-input" type="checkbox" :id="veh.name" v-on:click="addVeh(veh.name, $event)" :checked="this.service.veh.includes(veh.name)"></td>
                                              <td style="white-space: nowrap" v-if="veh.name == 'Limo'"><label :for="veh.name">{{ veh.name }}</label></td>
                                              <td v-if="veh.name == 'Limo'"><label>Number Needed: <input type="text" class="form-control" placeholder="1" v-model="service.limocount"></label></td>
                                             </tr>
                                        </table>
                                    </td>
                                    <td valign=top>
                                    <label><b>Select Personnel:</b></label>
                                    <table class="table" style="width: 50%">
                                        <thead><td>Select</td><td></td><td>Setup</td></thead>
                                         <tr v-for="(pers, id) in Perss" :key="id">
                                             <td><input class="form-check-input" type="checkbox" :id="pers.name" v-on:click="addPers(pers, $event)" :checked="this.checkedPers.find(x => x.name === pers.name)"></td>
                                             <td v-if="pers.name != 'None'"><label :for="pers.name">{{ pers.name }}</label></td>
                                             <td><input v-if="this.checkedPers.find(x => x.name === pers.name) && pers.name != 'None'" class="form-check-input" type="checkbox" :name="pers.setup" :id="pers.setup" v-on:click="checkPers(pers, $event)" :checked="this.checkedPers.find(x => x.name === pers.name).setup"></td>
                                             
                                        </tr>
                                    </table>
                                     </td>
                                </tr>
                            </table>
                            <h3 v-if="this.CustomFields.map((el) => el.category).indexOf('Other') != -1"><b>Other Info</b></h3>
                            <div v-for="(field, id) in CustomFields" :key="id">
                            <label v-if="field.category == 'Other'">{{field.name}}</label>&nbsp;&nbsp;
                            <input v-if="field.type == 'text' && field.category == 'Other'" type="text" class="form-control" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <input v-if="field.type == 'checkbox' && field.category == 'Other'" class="form-check-input" type="checkbox" :id="1234" v-model="customs[this.customs.map((el) => el.name).indexOf(field.name)].value">
                            <br>
                            </div>
                        
                             <br>
                             <br>
                            <div class="form-group" align=center>
                                <button class="btn btn-primary btn-block">Save Service</button>
                            </div>
                            <br>
                        </form>
                        </div>
                        </div>
                        </Lazy>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                checkedVehs: [],
                checkedPers: [],
                checkedSR: [],
                service: {
                },
                ServiceLocs: [],
                Cems: [],
                Vehs: [],
                Perss: [],
                BLs: [],
                Caskets: [],
                Srs: [{}],
                x_count: 0,
                FormUpdated: false,
                Containers: [],
                showVis: false,
                selectedCasket: "",
                lastSelectedCasket: "",
                Colors: [],
                colorfilter: "All Colors",
                subtracted: false,
                added: false,
                searchsl: "",
                searchcem: "",
                CustomsDone: false,
                CustomFields: [{
                    name: "",
                    type: "",
                    category: '',
                    }],
                customs: [{
                    name: '',
                    value: '',
                    category: '',
                }],
                noservice: false,
                otherServiceLoc: "",
                otherCem: "",
            }
        },
        
        computed: {
            filteredSearch:function() {
                return this.Caskets.filter((casket) => {
                    if(this.colorfilter == "All Colors") {
                        return this.Caskets;
                    }
                    else {
                        var theService = casket.color;
                        return theService.toLowerCase().match(this.colorfilter.toLowerCase());
                    }
                })
            },
            filteredSearchSL:function() {
                return this.ServiceLocs.filter((service) => {
                    var theService = service.name;
                    return theService.toLowerCase().match(this.searchsl.toLowerCase());
                })
            },
            filteredSearchCem:function() {
                return this.Cems.filter((service) => {
                    var theService = service.name;
                    return theService.toLowerCase().match(this.searchcem.toLowerCase());
                })
            },
        },
        created() {
            this.service.tenant = this.$tenant;
            
            if(this.ServiceLocs == null) {
                window.location.reload();
            }
            this.updateservices();
        },
        methods: {
            customfields() {
                console.log("CUSTOMS: "+this.CustomFields[0].name);
              for (let index = 0; index < this.CustomFields.length; ++index) {
                const element = this.CustomFields[index];
                console.log("ELEMENT: "+element)
                if(this.service.customs != null) {
                this.customs.push ({
                    name: element.name,
                    value: this.service.customs[index].value,
                    category: element.category,
                })
                }
                else {
                    this.customs.push ({
                    name: element.name,
                    value: "",
                })
                }
            }
            this.CustomsDone = true;
            console.log("CUSTOMS: "+this.customs);
            },
            updateservices() {
              let dbRef = db.collection('services').doc(this.$route.params.id);
            dbRef.get().then((doc) => {
                this.service = doc.data();
                this.checkedSR = doc.data().srs;
                this.checkedVehs = this.service.veh.split(",");
                this.checkedPers = doc.data().pers;
                if(!this.ServiceLocs.find(x => x.name === this.service.serviceLoc) && this.service.serviceLoc !== null && this.service.serviceLoc !== undefined) {
                    this.otherServiceLoc = this.service.serviceLoc;
                    this.service.serviceLoc = 'Other';
                }
                if(!this.Cems.find(x => x.name === this.service.cem) && this.service.cem !== null && this.service.cem !== undefined) {
                    this.otherCem = this.service.cem;
                    this.service.cem = 'Other';
                }
                console.log("CSRRRRR: ", this.checkedSR)
                db.collection('caskets').orderBy('name').onSnapshot((snapshotChange) => {
                this.Caskets = [];
                snapshotChange.forEach((doc) => {
                    console.log(this.service.casket)
                    if(doc.data().tenant == this.service.tenant && doc.data().available == true  && doc.data().deleted !== true) {
                    this.Caskets.push({
                        key: doc.id,
                        name: doc.data().name,
                        color: doc.data().color,
                    })
                    }
                    else if(doc.data().name == this.service.casket && doc.data().tenant == this.service.tenant) {
                        console.log("I DID IT!!!!!!!!!!")
                        this.Caskets.push({
                        key: doc.id,
                        name: doc.data().name,
                        color: doc.data().color,
                    })
                    }
                });
            })
                if(this.service.date == 'No Service') {
                    this.noservice = true;
                }
                else {
                    this.noservice = false;
                }
                console.log("VIIIIIIIIIIS:"+this.service.visitationstart)
                if(this.service.visitationstart != null && this.service.visitationstart != "") {
                    this.showVis = true;
                    console.log("VIIIIIIIIIIS: "+this.showVis);
                }
                this.lastSelectedCasket = this.service.casket;
                this.selectedCasket = this.service.casket;
            }).catch((error) => {
                console.log(error)
            })
            db.collection('tenants').orderBy('name').onSnapshot((snapshotChange) => {
                this.CustomFields = [];
                this.customs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().name == this.service.tenant) {
                        this.CustomFields = doc.data().fields.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
                    }
                });
            })
            db.collection('serviceLocs').orderBy('name').onSnapshot((snapshotChange) => {
                this.ServiceLocs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.ServiceLocs.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            db.collection('containers').orderBy('name').onSnapshot((snapshotChange) => {
                this.Containers = [{'name':'CVLT'}, {'name':'CBOX'}, {'name':'NOC'}];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Containers.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            db.collection('vehs').orderBy('name').onSnapshot((snapshotChange) => {
                this.Vehs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Vehs.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            db.collection('cems').orderBy('name').onSnapshot((snapshotChange) => {
                this.Cems = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Cems.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            db.collection('perss').orderBy('name').onSnapshot((snapshotChange) => {
                this.Perss = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Perss.push({
                        key: doc.id,
                        name: doc.data().name,
                        setup: doc.data().setup
                    })
                    }
                });
            })
            db.collection('bls').orderBy('name').onSnapshot((snapshotChange) => {
                this.BLs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.BLs.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            
            db.collection('colors').orderBy('name').onSnapshot((snapshotChange) => {
                this.Colors = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Colors.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            db.collection('srs').orderBy('name').onSnapshot((snapshotChange) => {
                this.Srs = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.service.tenant && doc.data().deleted !== true) {
                    this.Srs.push({
                        name: doc.data().name,
                        done: doc.data().done,
                    })
                    }
                });
            })  
                this.FormUpdated = true
            },
            UncheckVis(event) {
                if(event.target.checked == false) {
                    this.service.visitationstart = "";
                    this.service.visitationend = "";
                }
            },
            addVeh(theName, event) {
                if(this.checkedVehs == "") {
                    this.checkedVehs = this.service.veh.split(",");
                }
                if(this.checkedVehs.find(element => element === "None") != null) {
                    this.checkedVehs.splice(this.checkedVehs.indexOf('None'), 1)
                 }
                var index = this.checkedVehs.indexOf(theName);
                if(event.target.checked) {
                    this.checkedVehs.push(theName);
                }
                else {
                    this.checkedVehs.splice(index, 1)
                }
                console.log("Veh: "+this.checkedVehs);
            },
            selectCasket(name) {
                this.selectedCasket = name;
                this.service.casket = name;
            },
            selectSL(name) {
                this.service.serviceLoc = name;
            },
            selectCL(name) {
                this.service.cem = name;
            },
            addPers(pers, event) {
                if(this.checkedPers.find(element => element === "None") != null) {
                    this.checkedPers.splice(this.checkedPers.indexOf('None'), 1)
                 }
                console.log("CSR: ",this.checkedPers)
                var index = this.checkedPers.map((el) => el.name).indexOf(pers.name);
                if(event.target.checked) {
                    let entry = { name: pers.name, setup: false };
                    this.checkedPers.push(entry);
                }
                else {
                    this.checkedPers.splice(index, 1)
                }
            },
            checkPers(pers, event) {
                var index = this.checkedPers.map((el) => el.name).indexOf(pers.name);
                console.log(index)
                if(event.target.checked) {
                    this.checkedPers.splice(index, 1)
                    let entry = { name: pers.name, setup: true };
                    this.checkedPers.push(entry);
                }
                else {
                    this.checkedPers.splice(index, 1)
                     let entry = { name: pers.name, setup: false };
                     this.checkedPers.push(entry);
                }
            },
            addSR(sr, event) {
                console.log("CSR: ",this.checkedSR)
                var index = this.checkedSR.map((el) => el.name).indexOf(sr.name);
                if(event.target.checked) {
                    let entry = { name: sr.name, done: false };
                    this.checkedSR.push(entry);
                }
                else {
                    this.checkedSR.splice(index, 1)
                }
            },
            checkSR(sr, event) {
                var index = this.checkedSR.map((el) => el.name).indexOf(sr.name);
                console.log(index)
                if(event.target.checked) {
                    this.checkedSR.splice(index, 1)
                    let entry = { name: sr.name, done: true };
                    this.checkedSR.push(entry);
                }
                else {
                    this.checkedSR.splice(index, 1)
                     let entry = { name: sr.name, done: false };
                     this.checkedSR.push(entry);
                }
            },
            onUpdateForm(event) {
                if(this.service.serviceLoc == "Other") {
                    this.service.serviceLoc = this.otherServiceLoc;
                }
                if(this.service.cem == "Other") {
                    this.service.cem = this.otherCem;
                }
                console.log(this.customs);
                this.service.customs = this.customs;
                if(this.noservice == true) {
                    this.service.date = "No Service"
                }
                if(this.service.date == null || this.service.date == "" || this.service.date == "TBD" || this.service.date == "No Service") {
                    this.service.pending = true;
                }
                else {
                    this.service.pending = false;
                }
                if(this.service.limocount == null || this.service.limocount == "") {
                    this.service.limocount = 1;
                }
                console.log(this.checkedVehs)
                if(this.checkedVehs.length == 0) {
                    this.checkedVehs.push('None')
                }
                if(this.service.date == null || this.service.date == "") {
                    this.service.date = "TBD";
                }
                this.service.pers = this.checkedPers;
                this.service.veh = this.checkedVehs.join(', ')
                this.service.srs = this.checkedSR;
                console.log(this.service.srs);
                console.log("CASKET:  "+this.Caskets)
                event.preventDefault()
                if(this.lastSelectedCasket !== this.selectedCasket) {
                db.collection('caskets').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    //If original casket,
                    if(doc.data().name == this.lastSelectedCasket && doc.data().tenant == this.service.tenant) {
                        this.lastSelectedCasket = doc.data();
                        console.log("CASSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS"+this.selectedCasket)
                        // if(this.selectedCasket !== null && this.selectedCasket !== "") {
                            if(this.added == false) {
                                this.lastSelectedCasket.amount += 1;
                                console.log(this.lastSelectedCasket.amount)
                                this.lastSelectedCasket.available = true; 
                                db.collection('caskets').doc(doc.id)
                                .update(this.lastSelectedCasket).then(() => {
                                    console.log("Last Selected successfully updated!");
                                }).catch((error) => {
                                    console.log(error);
                                });
                                this.added = true;
                            }
                        // }
                        // else {
                        //      this.lastSelectedCasket.available = false;
                        //  }
                    }
                    //If new casket,
                    if(doc.data().name == this.selectedCasket && doc.data().tenant == this.service.tenant) {
                        this.selectedCasket = doc.data();
                        if(this.subtracted == false) {
                            this.selectedCasket.amount -= 1;
                            this.subtracted = true;
                        }
                        if(this.selectedCasket.amount < 1) {
                            this.selectedCasket.available = false; 
                        }
                        db.collection('caskets').doc(doc.id)
                .update(this.selectedCasket).then(() => {
                    console.log("Selected successfully updated!");
                }).catch((error) => {
                    console.log(error);
                });
                    }
                    
                })
                });
                }
                db.collection('services').doc(this.$route.params.id)
                .update(this.service).then(() => {
                    console.log("Service successfully updated!");
                    this.$router.push('/')
                }).catch((error) => {
                    console.log(error);
                });
                
            }
        }
    }
</script>